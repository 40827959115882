import '../Dashboard.css';
import React, { useState, useEffect } from 'react';
import { Icon } from "hcss-components";
import { Link } from "react-router-dom";
import { getIncidentAsync } from "../../../API/getAPI";

import { ProductDTO } from '../../../DTO/ProductDTO';
import { IncidentDTO } from '../../../DTO/IncidentDTO';
import { IncidentUpdateDTO } from '../../../DTO/IncidentUpdateDTO';
import { DisplayDateString } from '../../../DTO/Constants/Function/DateConversion';

import { IsUserAdmin } from '../../../Auth/UserAuth';

export const ActiveIncident = (props: IncidentDTO): JSX.Element => {
  const [currentPathname, setCurrentPathname] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [formProperties, setFormProperties] = useState<IncidentDTO>();
  useEffect(() => {
    setCurrentPathname(window.location.pathname);
  });
  const handleClick = () => {
    setIsMounted(true);
    async function getIncident() {
      await getIncidentAsync(props.id).then(resolution => {
        if(isMounted) {
          setFormProperties(resolution.data);
        }
      });
    }
    getIncident();
  }

  const productsAffectedDisplay = props.products.map((product: ProductDTO, index: number) => {
    return(<li key={index}>{product.name}<Icon name={product.statusLabel.iconName} style={{color: product.statusLabel.iconColor}} margin='left'></Icon></li>);
  })

  let incidentStatusMessage: string = '';
  if(props.incidentStatus.name === 'Investigating') {
    incidentStatusMessage = 'The incident is currently under investigation.';
  } else if (props.incidentStatus.name === 'Identified') {
    incidentStatusMessage = 'The root cause of the incident has been identified.';
  } else if (props.incidentStatus.name === 'Maintenance') {
    incidentStatusMessage = 'The incident is currently under maintenance.';
  } else {
    incidentStatusMessage = 'The incident has been resolved.';
  }

  const startDateTimeString: string = DisplayDateString(new Date(props.startTime), 'LLL', false, true);
  let expectedResolveTimeString: string = 'We are unsure of the resolution time.';
  if(props.expectedResolveTime) {
    expectedResolveTimeString = 'We expect this to be resolved by ' + DisplayDateString(new Date(props.expectedResolveTime), 'LLL', false, true);
  }

  let statusUpdatesDisplay:JSX.Element[] = [];
  if(props.incidentUpdates) {
    statusUpdatesDisplay = props.incidentUpdates.map((update: IncidentUpdateDTO, index: number) => {
      const updateTime: string = DisplayDateString(new Date(update.time), 'LLL', false, true);
      return(<li key={index}>{updateTime} - {update.details}</li>);
    })
  }

  
  const renderEditIncidentButton = () => {
    if (IsUserAdmin()) {
      return(
        <div data-testid='edit'>
          <Link to={{pathname: `/Forms/Incident/${props.id}`, state: { from: currentPathname }}} onClick={handleClick}>Edit Incident
            <Icon name='edit' margin='both'></Icon>
          </Link>
        </div>
      );
    }
  }

  return(
    <div className='content'>
      <button data-testid='button' className='header' onClick={() => setIsHidden(previous => !previous)} style={{borderBottom: isHidden ? '0' : '1px solid rgb(211,211,211)'}}>
        <div className='button-title full-width'>
          <div data-testid='name' style={{fontSize: '20px', marginLeft: '6.5px'}}>{props.title.charAt(0).toUpperCase() + props.title.slice(1)}</div>
          <Icon name={isHidden ? 'chevron-right' : 'chevron-down'} margin='right' className='fa-lg'></Icon>
        </div>
        <div data-testid='description' style={{textAlign: 'left', marginBottom: 0, marginLeft: '6.5px'}}>{props.description}</div>
      </button>
      <div data-testid='content' className='content-expanded' style={{display: isHidden ? 'none' : 'block'}}>
        <div>
          <b>Products Affected:</b>
          <ul>
            {productsAffectedDisplay}
          </ul>
        </div>
        <div>{incidentStatusMessage}</div>
        <div data-testid='expected'><b>Expected Resolution Date Time: </b>{expectedResolveTimeString}</div>
        <div data-testid='reported' ><b>Reported Date Time: </b>{startDateTimeString}</div>
        <div style={{marginBottom: '0px', display: (statusUpdatesDisplay.length ? 'block' : 'none')}}>
          <b>Status Updates:</b>
          <ul>
            {statusUpdatesDisplay}
          </ul>
        </div>
        {renderEditIncidentButton()}
      </div>
    </div>
  );
}